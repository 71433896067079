.repeaters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
}
.image-repeater-wrapper {
    padding: 15px;
    // width: 50%;
    // &:nth-of-type(odd) {
    //     padding-right: 5px;
    // }
    // &:nth-of-type(even) {
    //     padding-left: 5px;
    // }
    .box-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .files {
            // width: 100%;
            .drop-area {
                max-width: 195px;
                max-height: 195px;
                min-height: 195px;
                min-width: 195px;
                margin: 0 auto;
            }
        }
    }
}