.box-wrapper {
    display: flex;
    flex-direction: row;
    @media( max-width: 750px ) {
        flex-direction: column;
    }
    .box {
        width: 50%;
        &:nth-of-type(1) {
            padding-right: 15px;
        }
        &:nth-of-type(2) {
            padding-left: 15px;
        }
        @media( max-width: 750px ) {
            width: 100%;
            padding: 0 !important;
        }
        .color-picker {
            padding: 0 15px;
            display: flex;
            align-items: center;
            @media( max-width: 1000px ) {
                flex-direction: column;
            }
            p {
                padding-left: 15px;
                width: 100%;
                font-size: 36px;
                @media( max-width: 1000px ) {
                    font-size: 24px;
                    padding-left: 0;
                    padding-top: 15px;
                    text-align: center;
                }
            }
        }
    }
}