header {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    position: absolute;
    right: 1rem;
    top: 0;
    z-index: 999999999999999999999;
    @media( max-width: 576px) {
        margin-right: 40px;
    }
    
    nav {
        ul {
            display: flex;
            align-items: center;
        }
        li {
            &:first-child {
                padding-right: 1rem;
            }
            span {
                color: $dark-blue;
            }
            i {
                cursor: pointer;

                &:hover {
                    color: $red;
                }
            }
        }
    }
}

