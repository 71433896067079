.founding {
    textarea {
        height: 300px;
        max-height: 300px;
    }
    .repeaters {
        display: flex;
        flex-wrap: wrap;
    }
    .inputs-wrapper {
        .btn-box {
            .btn {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}