.textarea-wrapper {
    label {
        padding-bottom: 5px;
    }
    textarea {
        resize: none;
        width: 100%;
        padding: 7px 0;
        border: none;
        outline: none;
    }
}