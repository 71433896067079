$dark-blue: #5A503C;
$red: #d32f2f;
$green: #2eaa05;
$ico: #a0a0a0;
$ico-hover: #ffffff;
$li-hover: #cfcfcf;
$base: #3c4858;
$wrapper: #f0f0f0;
$menu-image: url('./../../../img/bg-menu.jpg');
$menu-bg: rgba(196, 196, 196, 0.7);

$textColor: #000000;
$mainColor: #5A503C;
$inputBorderFocus: #5A503C;
$iconBackground: #5A503C;
$buttonColor: #5A503C;
$buttonColorHover: #7C7855;
$errorColor: #d32f2f;
$editColor: #d3b000;