.drag-slots-wrap {
    margin: 30px 0;

    .drag-slot {
        width: 100%;
        height: 200px;
        border: 1px $mainColor dashed;
        margin-bottom: 25px;
        position: relative;

        i {
            position: absolute;
            background-color: $mainColor;
            color: #fff;
            right: 10px;
            top: 10px;
            border-radius: 100%;
            padding: 3px 8px;
            transition: .2s ease-in-out background-color;
            cursor: pointer;
            
            &:hover {
                background-color: $red;
            }
        }
    }
}
