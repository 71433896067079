.pagination-bottom {
    margin-bottom: 30px;
    .-pagination {
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        padding: 3px;
        box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
        border-top: 2px solid rgba(0,0,0,0.1);
        .-previous {
            flex: 1 1;
            text-align: center;
            .-btn[disabled] {
                opacity: .5;
                cursor: default;
            }
            .-btn {
                display: block;
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 3px;
                padding: 6px;
                font-size: 1em;
                color: rgba(0,0,0,0.6);
                background: rgba(0,0,0,0.1);
                transition: all .1s ease;
                outline-width: 0; 
            }
        }
        .-center {
            flex: 1.5 1;
            text-align: center;
            margin-bottom: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;
            .-pageInfo {
                display: inline-block;
                margin: 3px 10px;
                white-space: nowrap;
                .-pageJump {
                    display: inline-block;
                    input {
                        width: 70px;
                        text-align: center;
                        border: 1px solid rgba(0,0,0,0.1);
                        background: #fff;
                        padding: 5px 7px;
                        font-size: inherit;
                        border-radius: 3px;
                        font-weight: normal;
                        outline-width: 0;
                    }
                }
            }
        }
        .-next {
            flex: 1 1;
            text-align: center;  
            .-btn {
                display: block;
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 3px;
                padding: 6px;
                font-size: 1em;
                color: rgba(0,0,0,0.6);
                background: rgba(0,0,0,0.1);
                transition: all .1s ease;
                cursor: pointer;
                outline-width: 0;
            }
        }
    }
}