.card {
    margin-bottom: 2rem;
    margin-top: 2rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(180, 180, 180, 0.23);
    background-color: #fff;
    border-radius: 5px;

    &.card-stats {
        .card-header {
            i {
                background-color: $iconBackground;
            }
            &:after {
                width: calc(100% - 2rem);
                content: ' ';
                height: 1px;
                display: block;
                background-color: #e9e9e9;
                position: absolute;
                bottom: 0;
            }
        }
    }

    &.card-posts {
        .card-header {
            p {
                strong {
                    margin-top: 0;
                    margin-bottom: 0.25rem;
                }
            }

            img {
                width: 80px;
                height: 80px;
                object-fit: cover;
            }

            &:after {
                width: calc(100% - 2rem);
                content: ' ';
                height: 1px;
                display: block;
                background-color: #e9e9e9;
                position: absolute;
                bottom: 0;
            }
        }

        .card-dest {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            a {
                margin-bottom: 1rem;
            }

            .post-icons {
                i {
                    cursor: pointer;

                    &:nth-of-type(2) {
                        &:hover {
                            color: $red;
                        }
                    }

                    &:first-of-type {
                        &:hover {
                            color: $dark-blue;
                        }
                    }
                }
            }
        }
    }

    &.card-notify {
        color: #fff;
        display: flex;
        align-items: center;
        padding: 1rem;
        position: relative;

        &.error {
            background-color: #e63939;
        }

        &.succes {
            background-color: #55b559;
        }

        &.mood {
            background-color: #ff9e0f;
        }


        i {
            font-size: 36px;
            margin-right: 1rem;
        }

        .close-modal {
            position: absolute;
            top: 0.5rem;
            right: -0.5rem;
            cursor: pointer;

            i {
                font-size: 20px;
                color: #e9e9e9a8;
            }
        }
    }

    &.card-data {
        .card-header {
            .card-title {
                padding: 1rem;
                background: $mainColor;
                color: #fff;
                width: 100%;

                h5 {
                    font-weight: 300;
                    margin-bottom: 0.5rem;
                }

                p {
                    font-size: 0.75rem;
                }
            }
        }

        .table-body {
            padding: 1rem 1rem 2rem 1rem;
            overflow-x: auto;

            table {
                width: 100%;
                overflow-x: auto;

                .text-warning {
                    color: #e63939;
                }

                thead {
                    tr {

                        th,
                        td
                            {
                            font-size: 1rem;
                            padding: 1rem;
                            text-align: left;
                        }

                        th {
                            border-bottom: 2px solid rgba(0, 0, 0, .06);
                        }
                    }
                }

                tbody {
                    tr {
                        border-bottom: 1px solid rgba(0, 0, 0, .06);
                        transition: 0.2s;

                        &:hover {
                            background-color: rgba(117, 117, 117, 0.06);
                        }

                        td {
                            font-size: 0.8750rem;
                            padding: 1rem;
                            vertical-align: middle;

                            i {
                                cursor: pointer;

                                &:first-of-type {
                                    margin-right: 0.25rem;
                                }

                                &.delete {
                                    color: #e63939;
                                }

                                &:hover {
                                    color: $dark-blue;
                                }
                            }
                        }
                    }
                }

                [type="checkbox"]:not(:checked),
                [type="checkbox"]:checked {
                    position: absolute;
                    left: -9999px;
                }

                [type="checkbox"]:not(:checked)+label,
                [type="checkbox"]:checked+label {
                    position: relative;
                    padding-left: 25px;
                    cursor: pointer;
                }

                [type="checkbox"]:not(:checked)+label:before,
                [type="checkbox"]:checked+label:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: -5px;
                    width: 22px;
                    height: 22px;
                    background: #fff;
                    border-radius: 3px;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                    border-radius: 5px;
                    background-color: transparent;
                    outline: none;
                }

                [type="checkbox"]:not(:checked)+label:after,
                [type="checkbox"]:checked+label:after {
                    content: '✔';
                    position: absolute;
                    top: 0px;
                    left: 5px;
                    font-size: 18px;
                    line-height: 0.8;
                    color: #e63939;
                    transition: all .2s;
                }

                [type="checkbox"]:not(:checked)+label:after {
                    opacity: 0;
                    transform: rotate(360deg) scale(0);
                }

                [type="checkbox"]:checked+label:after {
                    opacity: 1;
                    transform: rotate(0) scale(1);
                }
               
            }
        }

        form {
            padding: 1rem;

            .form-group {
                padding-bottom: 10px;
                position: relative;
                margin: 27px 0 0;
            }

            .bmd-form-group:not(.has-success):not(.has-danger) [class*=" bmd-label"].bmd-label-floating,
            .bmd-form-group:not(.has-success):not(.has-danger) [class^=bmd-label].bmd-label-floating {
                color: #aaa;
                &.error {
                    color: $red !important;
                }
            }

            .bmd-form-group [class*=" bmd-label"].bmd-label-floating,
            .bmd-form-group [class^=bmd-label].bmd-label-floating {
                will-change: left, top, contents;
                margin: 0;
                line-height: 1.4;
                font-weight: 400;
            }

            // .bmd-form-group .bmd-label-floating,
            // .bmd-form-group .bmd-label-placeholder {
            //     top: .6125rem;
            // }

            .bmd-form-group [class*=" bmd-label"],
            .bmd-form-group [class^=bmd-label] {
                position: absolute;
                pointer-events: none;
                transition: all .3s ease;
            }

            .bmd-form-group .checkbox label,
            .bmd-form-group .radio label,
            .bmd-form-group label {
                font-size: .875rem;
            }

            .bmd-form-group label {
                color: #aaa;
            }

            .bmd-form-group .form-control,
            .bmd-form-group input::placeholder,
            .bmd-form-group label {
                line-height: 1.1;
            }

            .bmd-label-floating,
            .bmd-label-placeholder {
                top: .6125rem;
            }

            [class*=" bmd-label"],
            [class^=bmd-label] {
                color: #999;
            }

            .form-check,
            label {
                font-size: 14px;
                line-height: 1.42857;
                color: #aaa;
                font-weight: 400;
            }

            .form-control[disabled],
            .form-group .form-control[disabled],
            fieldset[disabled] .form-control,
            fieldset[disabled] .form-group .form-control {
                background-color: transparent;
                cursor: not-allowed;
                color: #aaa;
                border-bottom: 1px dotted #d2d2d2;
                background-repeat: no-repeat;
            }

            .bmd-form-group .form-control,
            .bmd-form-group input::placeholder,
            .bmd-form-group label {
                line-height: 1.1;
            }

            .form-control.disabled,
            .form-control:disabled,
            .form-control[disabled],
            fieldset[disabled][disabled] .form-control {
                background-image: linear-gradient(90deg, #d2d2d2 0, #d2d2d2 30%, transparent 0, transparent);
                background-repeat: repeat-x;
                background-size: 3px 1px;
            }

            .form-control:disabled,
            .form-control[readonly] {
                background-color: #e9ecef;
                opacity: 1;
            }

            .form-control,
            input::placeholder,
            label {
                line-height: 1.1;
            }


            .is-filled .bmd-label-floating,
            .bmd-label-floating {
                top: -1rem;
                left: 0;
                font-size: .6875rem
            }

            .form-control {
                display: block;
                width: 100%;
                padding: .4375rem 0;
                font-size: 1rem;
                height: 36px;
                line-height: 1.5;
                color: $textColor;
                background-color: transparent;
                background-clip: padding-box;
                border: 1px solid #fff;
                box-shadow: none;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

                &:focus {
                    color: $textColor;
                    background-color: transparent;
                    outline: 0;
                }

                outline: none;

            }

            .form-control {
                background: no-repeat bottom, 50% calc(100% - 1px);
                background-size: 0 100%, 100% 100%;
                border: 0;

                transition: background 0s ease-out;
                padding-left: 0;
                padding-right: 0;
                border-radius: 0;
            }

            .form-control:focus {
                background-size: 100% 100%, 100% 100%;
                transition-duration: .3s;
                box-shadow: none;
            }

            .form-control,
            .is-focused .form-control {
                background-image: linear-gradient(0deg, $inputBorderFocus 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, hsla(0, 0%, 82%, 0) 0);
            }



            textarea {
                min-height: 100px;
            }

            .btn {
                margin-top: 1rem;
            }
            .checkbox {
                max-width: max-content;
                display: flex;
                align-items: center;
                position: relative;
                padding-left: 35px;
                height: 25px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;

                    &:checked ~ .checkmark {
                        background-color: $dark-blue;
                        transition: background-color .2s ease-in-out;
                        &:after {
                            display: block;
                        }
                    }
                }
                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: #eee;
                    border-radius: 50%;
                    transition: 0.2s;
                    
                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                }
                &:hover input ~ .checkmark {
                    background-color: $dark-blue;
                }
            }
        
            
            .checkbox .checkmark:after {
                top: calc(50% - 4px);
                left: calc(50% - 4px);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: white;
            }

            .select {
                font-size: 16px;
                position: relative;
                display: inline-block;
                max-width: 260px;
                width: 100%;

                select {
                    outline: none;
                    -webkit-appearance: none;
                    display: block;
                    padding: .5rem 1rem;
                    margin: 0;
                    transition: border-color 0.2s;
                    border: 1px solid #d2d2d2;
                    border-radius: 5px;
                    width: 100%;
                    background: #fff;
                    color: #555;
                    line-height: normal;
                    font-family: inherit;
                    font-size: inherit;
                    line-height: inherit;
                  }
                  .arr {
                    color: $dark-blue;
                    font-size: 30px;
                    position: absolute;
                    right: 1rem;
                    top: 2px;
                    width: 20px;
                    pointer-events: none;
                  }
              }
              .editor-form {
                  
                .bmd-form-group [class^=bmd-label] {
                    position: static !important;
                    display: block;
                    margin-bottom: 1rem;
                }
                .ql-toolbar.ql-snow,
                .ql-container.ql-snow {
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 1px solid #ccc;
                }
              }
        }
        .switching {
            label {
                position: relative!important;
                display: block;
            }
        }
        .switch {
            width: 130px;
            border: 1px solid #d2d2d2;
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
            overflow: hidden;
            margin: 0;

            &.active {
                .slide {
                    transform: translateX(65px);
                    background: $mainColor;
                }
            }
            .slide {
                height: 40px;
                width: 50%;
                background: #aaa;
                transition: .2s ease-in-out transform;
                -webkit-box-shadow: -5px 3px 16px -10px rgba(0,0,0,0.75);
                -moz-box-shadow: -5px 3px 16px -10px rgba(0,0,0,0.75);
                box-shadow: -5px 3px 16px -10px rgba(0,0,0,0.75);
            }
        }
        .repeat-password {
            margin-top: 20px;
        }
        .avatar {
            background-color: #aaa;
            width: 250px;
            height: 200px;
            height: 100%;
            margin: 0 auto;
            border: 1px solid #aaa;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                &.error {
                    border: 1px solid #d32f2f;
                }
            }
        }
        .upload-column {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
        .upload-btn-wrapper {
            position: relative;
            overflow: hidden;
            display: inline-block;
            text-align:  center;
            cursor: pointer;

            .btn {
                padding: 8px 20px;
                margin: 0 auto;
                cursor: pointer;
            }
          }
          
          .upload-btn-wrapper input[type=file] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;

          }
    }

    &-header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        @media (max-width: 576px) {
            flex-direction: column;
        }

        .card-over {
            margin-top: -2rem;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(160, 160, 160, 0.16), 0 3px 6px rgba(129, 129, 129, 0.23);
        }


        i {
            padding: 1.5rem;
            font-size: 30px;
            background: #e63939;
            color: #fff;

            @media (max-width: 576px) {
                margin-bottom: 1rem;
            }
        }

        strong {
            display: block;
            margin-top: 0.5rem;

            font: {
                size: 1.250rem;
                weight: 500;
            }

            @media (min-width: 576px) {
                text-align: right;
            }
        }

        p {
            font: {
                size: .8750rem;
                weight: 300;
            }
        }
    }

    &-dest {
        padding: 1rem;
        display: flex;
        align-items: center;

        i {
            margin-right: .25rem;
        }

        a {
            font-size: .750rem;
            cursor: pointer;
        }
    }

    &-chart {
        padding: 1rem 1rem .5rem 1rem; 
        .ct-chart {
            background-color: #fff;
            border-radius: 5px;
        }
    }
}