.editor {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .header {
        background: #5A503C;
        color: #fff;
        border-radius: 3px;
        width: 100%;
        padding: 10px 15px;
        border-bottom: 1px solid #d8d7d7;
    }
    select {
        padding: 10px 25px;
        width: 100%;
        border-bottom: 2px solid rgba(160, 160, 160, 0.16);
        outline: none;
    }
    input {
        padding: 15px;
        border-bottom: 2px solid rgba(160, 160, 160, 0.16);
        outline: none;
    }
    .imageInput {
        background: #fff;
    }
    textarea {
        height: 400px;
        max-height: 400px;
        resize: none;
        box-shadow: 0 3px 6px rgba(160, 160, 160, 0.16), 0 3px 6px rgba(129, 129, 129, 0.23);
        outline: none;
        padding: 15px;
    }
    .btn-wrap-send {
        padding: 45px 0;
        display: flex;
        justify-content: center;
    }
}