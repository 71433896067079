.card-box {
    display: flex;
    @media( max-width: 1100px ) {
        flex-direction: column;
    }
    .upload-locals {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40%;
        @media( max-width: 1100px ) {
            width: 100%;
        }
        .size-btn {
            min-width:300px;
            @media( max-width: 1270px ) {
                min-width: 250px;
            }
            @media( max-width: 1100px ) {
                min-width: 300px;
            }
            @media( max-width: 700px ) {
                // width: 200px;
                min-width: 100%;
            }
            .btn {
                margin-right: 0;
            }
        }
        .input-file-container {
            position: relative;
            .input-file-trigger {
                text-align: center;
                display: block;
                padding: 14px 45px;
                background: $green;
                color: #fff;
                font-size: 1em;
                transition: all .4s;
                cursor: pointer;
            }
            .input-file {
                position: absolute;
                top: 0; left: 0;
                opacity: 0;
                padding: 14px 0;
                cursor: pointer;
                width: 100%;
            }
            .input-file:hover + .input-file-trigger,
            .input-file:focus + .input-file-trigger,
            .input-file-trigger:hover,
            .input-file-trigger:focus {
                background: $editColor;
                color: #fff;
            }
        } 
    }
    .info {
        width: 60%;
        padding-bottom: 40px;
        @media( max-width: 1100px ) {
            width: 100%;
        }
        h2 {
            padding: 15px;
            @media( max-width: 500px ) {
                font-size: 24px;
            }
        }
        .pkt {
            display: flex;
            padding: 15px;
            span {
                padding-right: 15px;
                font-weight: bold;
                @media( max-width: 500px ) {
                    padding-right: 5px;
                }
            }
            p {
                a {
                    color: $inputBorderFocus;
                    font-weight: bold;
                    @media( max-width: 500px ) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}