.menu {
	position: fixed;
	height: 100vh;
	max-width: 260px;
	width: 100%;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	transition: .3s;
	z-index: 2;
	
	@media (max-width: 576px) {
		max-width: 100%;
	}

	&-wrapper {
		position: relative;
		z-index: 4;
		height: 100%;
		min-height: 500px;
	}

	h1 {
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		padding: 1rem 1rem 1.5rem 1rem;
		border-bottom: 1px solid #d8d7d7;
		max-width: calc(280px - 1rem);
		margin-left: auto;
		margin-right: auto;

		small {
			font-size: .9rem;
			height: 16px;
		}

		img {
			display: block;
			margin-bottom: 1rem;
			width: 45px!important;
		}
		small, img {
			width: 150px;
			overflow: hidden;
			transition: width .1s;

			@media (max-width: 576px) {
				width: 130px;
			}
		}
	}	

	ul {
		padding: 1rem;
		margin-top: 0.5rem;
		li {
			padding: .35rem;
			width: 100%;
			margin-bottom: 1rem;
			// transition: .2s ease-in-out background-color;
			border-radius: 5px;
			cursor: pointer;
			a {
				font: {
					size: calc(14rem / 16);
					weight: 300;
				}
				display: flex;
				align-items: center;
				padding-left: .5rem;
				color: $ico;
				width: 100%;
				i {
					margin-right: 1rem;
					font-size: 27px;
				}
			}
			&.active {
				background-color: $dark-blue;
				box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
				
				&:hover {
					background-color: $dark-blue;
				}

				a {
					color: $ico-hover;
				}
			}
			&:hover {
				background-color: $li-hover;
				a {
					color: $ico-hover;
				}
				& > ul  { 
					transform: translateX(0);
					position: relative;
				}
			}
			& > ul  {
				transform: translateX(-300px);
				padding-top: 5px;
				padding-bottom: 5px;
				position: absolute;
				li {
					&:last-child {
						margin-bottom: 0;
					}
					&:hover {
						background-color: #9e9e9e;
					}
					& > ul  {
						transform: translateX(-300px);
						padding-top: 5px;
						padding-bottom: 5px;
						position: absolute;
						li {
							&:last-child {
								margin-bottom: 0;
							}
							&:hover {
								background-color: #686565;
							}
						}
					}
				}
			} 
		}
	}
	.description {
		position: absolute;
		bottom: 1rem;
		left: 1rem;
		display: block;
		color: $ico;
		font-size: 0.750rem;
		display: flex;
		align-items: center;

		img {
			height: 30px;
			margin-right: 1rem;
			transition: .1s;
			width: 21px;
		}
		span {
			width: 120px;
			height: 12px;
			overflow: hidden;
			transition: .1s;
			display: block;
		}
	}

	button.slide-menu {
		background: transparent;
		border: unset;
		position: absolute;
		right: 1rem;
		top: 1rem;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $dark-blue;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
		padding: 3px 6px;
		border-radius: 5px;
		z-index: 9;
		outline: unset;

		i {
			color: #fff;
			font-size: 20px;
		}
	}

	.menu-bg {
		background-image: $menu-image;
		position: absolute;
		z-index: 1;
		height: 100%;
		width: 100%;
		display: block;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: 50%;

		&:after {
			position: absolute;
			z-index: 3;
			width: 100%;
			height: 100%;
			content: "";
			display: block;
			background: #fff;
			opacity: .93;
		}
	}
}

.menu-toggle {
	button.slide-menu {
		i {
			transform: rotate(270deg);
		}
	}
	.menu {
		max-width: 85px;

		h1 {
			width: 0;
			border-color: transparent;
			img, small {
				width: 0;
			}
		}
		ul {
			position: absolute;
			top: 45px;
			max-width: 85px;
			li {
				a {
					padding-left: 0;
					font-size: 20px;
					span {
						display: none;
					}
					i {
						margin-right: 0;
					}
				}
				& > ul {
					padding-left: 0;
					top: 0;

					li {
						padding-left: 6px;
					}
				}
			}
			
		}
		.description {
			left: calc(50% - 15px);
			transition: .2s;
			span {
				width: 0;
			}
			img {
				margin-right: 0;
			}
		}
	}
	main {
		padding-left: 93px;
	}
}