@import url('https://fonts.googleapis.com/css?family=Material+Icons');

* {
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    font-family: 'Roboto', sans-serif;
    color: $base;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
    vertical-align: middle;
}

a {
    text-decoration: none;
}

.wrapper {
    width: 100%;
    position: relative;
    background-color: $wrapper;
    min-height: 100vh;
}

main {
    padding-top: 4rem;
    position: relative;
    padding-left: 276px;
    margin-left: 1rem;
    margin-right: 1rem;
    transition: 0.3s;

    @media (max-width: 576px) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}


h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: calc(36rem / 16);
}

h3 {
    font-size: calc(24rem / 16);
}

h4 {
    font-size: calc(20rem / 16);
}

h5 {
    font-size: calc(18rem / 16);
}

h6 {
    font-size: 1rem;
}

p {
    font: {
        weight: 400;
        size: 1rem;
    }
}

button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 0;
    max-width: 100%;
}

body, .table-body {
    &::-webkit-scrollbar {
        width: 0.5em;
    }
}
 
body, .table-body {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
    }
}
 
body, .table-body {
    &::-webkit-scrollbar-thumb {
        background-color: $dark-blue;
        outline: none;
      }
}

.section-header {
    margin-bottom: 2rem;
}

