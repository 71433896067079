.vr-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    .dl-list {
        width: 100%;
    }
    .image-repeater-wrapper  {
        width: 100%;
        .remove-vr {
            display: flex;
            justify-content: flex-end;
            span {
                background-color: red;
                padding: 10px 15px;
                color: #fff;
                cursor: pointer;
            }
        }
        iframe {
            height: 100vh;
        }
    }
}

.wrap-vr {
    width: 100%;
    height:100%;
    .header {
        width: 100%;
        background: #e5e5e5;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        margin: 15px 0;
    }
}