.drop-area {
    width: 100%;
    height: 100%;
    min-height: 320px;
    max-height: 320px;
    position: relative;
    .place {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #d8d7d7;
        img {
            max-height: 90%;
            max-width: 90%;
        }
    }
    .button-wrapper {
        position: absolute;
        top: 0;
        button {

        }
    }
}