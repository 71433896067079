.select-items {
    .box {
        display: flex;
        padding: 7px 0;
        width: 100% !important;
        .col {
            height: 200px;
            overflow-y: auto;
            width: 50%;
            margin: 10px 15px;
            border: 1px solid #d2d2d2;
            &:nth-of-type(1) {
                background-color: #F9F9F9;
            }
            span {
                padding: 7px;
                display: block;
                cursor: pointer;
                &:hover {
                    background-color: $mainColor;
                    color: #fff;
                }
                &.disabled {
                    cursor: default;
                    color: rgb(199, 193, 193);
                    &:hover {
                        background-color: transparent;
                        color: rgb(199, 193, 193);
                    }
                }
            }
        }
    }
}