.ReactTable {
    margin: 30px 0 0 0;
    .rt-th {
        font-size: 15px;
    }
    .rt-td {
        padding: 11px 10px!important;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}