.navigation {
    margin: 10px;
    
    small {
        display: block;
        a {
            text-decoration: none;
            color: #000;
            font-weight: bold;
            transition: .5s;
            &:hover {
                // text-transform: uppercase;
            }
        } 
    }
}
