.inputs-wrapper {
    max-width: 100%;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-bottom: 50px;
    .header {
        background: #5A503C;
        color: #fff;
        border-radius: 3px;
        width: 100%;
        padding: 10px 15px;
        border-bottom: 1px solid #d8d7d7;
    }
    .input-wrapper {
        display: flex;
        border-bottom: 1px solid #d8d7d7;
        .number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            border-right: 1px solid #d8d7d7;
        }
        .box {
            width: 50%;
            padding: 20px 15px;
            &:nth-child(1) {
                padding-right: 15px;
            }
            &:nth-child(2) {
                padding-left: 15px;
            }
            .item {
                display: flex;
                flex-direction: column;
                padding-bottom: 15px;
                label {
                    margin-bottom: 5px;
                    font-size: .875rem;
                    line-height: 1.42857;
                    color: #aaa;
                    font-weight: 400;
                }
                input {
                    width: 100%;
                    line-height: 1.1;
                    background-color: transparent;
                    border-bottom: 2px solid #d8d7d7;
                    background-repeat: no-repeat;
                    outline: none;
                    transition: .3s;
                    padding-bottom: 5px;
                    &:focus {
                        border-bottom: 2px solid #000;
                    }
                }
            }
        }
    }
    .btn-box {
        display: flex;
        justify-content: center;
        .btn {
            width: 50%;
            &:nth-of-type(1) {
                margin-right: 5px;
            }
            &:nth-of-type(2) {
                margin-left: 5px;
            }
        }
    }
}