.loader-box {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background: #fff;
    z-index: 9999;
    &.block {
        display: block;
    }
    .loader {
        z-index: 9999999;
        position: fixed;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        border: 8px solid $menu-bg;
        border-top: 8px solid $dark-blue;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: spin 2s linear infinite;
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    }
}