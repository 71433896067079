.login-wrapper {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('./../../img/bg-login.jpg');
    
    .overlay {
        background-color: rgba(255,255,255, .75);
        width           : 100%;
        min-height      : 100%;
        position        : absolute;
        z-index         : 1;
        left            : 0;
        top             : 0;
    }
    img {
        position: relative;
        z-index: 2;
        width: 75px;
        margin-top: 20px;
    }
    .login {
        margin-top: 30px;
        max-width: 400px;
        width: 100%;
        padding: 30px;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        background-color: rgba(255,255,255, 1);
        margin-bottom: 20px;

        h1 {
            margin-bottom: 30px;
            text-align: center;
            font-size: 18px;
        }
        label {
            font-size: 13px;
            margin-bottom: 10px;
            color: #6d6d6d;
        }
        span.error {
            color: $red;
            font-size: 16px;
            width: 100%;
            display: block;
            position: relative;
            margin  : 0 0 10px 0;

            &:before {
                content  : "■";
                position    : relative;
                color       : $red;
                margin-right: 10px;
                top         : -2px;
            }
        }
        span.success {
            color: $green;
            font-size: 16px;
            width: 100%;
            display: block;
            position: relative;
            margin  : 0 0 10px 0;

            &:before {
                content  : "■";
                position    : relative;
                color       : $green;
                margin-right: 10px;
                top         : -2px;
            }
        }
        input {
            width: 100%;
            padding: 10px 15px;
            margin: 0 0 20px 0;
            border: 1px solid #dedede;
        }
        button {
            width: 100%;
            margin: 30px 0 0;
            background: $buttonColor;
            border: none;
            color: #fff;
            padding: 15px 10px;
            cursor: pointer;
            transition: .2s ease-in-out all;
            -webkit-transition: .2s ease-in-out all;
            -moz-transition: .2s ease-in-out all;
            -ms-transition: .2s ease-in-out all;
            -o-transition: .2s ease-in-out all;
            
            &:hover {
                background-color: $buttonColorHover;
            }
        }
    }
}
