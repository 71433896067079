.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark-blue;
    color: #fff;
    box-shadow: 0 2px 2px 0 rgba(39, 60, 176,.14), 0 3px 1px -2px rgba(39, 60, 176, 0.2), 0 1px 5px 0 rgba(39, 60, 176,.12);
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    border: none;
    padding: 10px;

    font: {
        size: 1rem;
        weight: 400;
    }
    i {
        margin: 0 10px 0 0;
    }

    &:hover {
        background-color: $buttonColorHover;
    }
    &.medium {
        width: 200px;
        height: 40px;
        line-height: 40px;
    }
    &.large {
        width: 260px;
        height: 60px;
        line-height: 60px;
        font-size: 1.5rem;
    }
    &.x-large {
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 1.5rem;
    }
    &.error {
        background-color: $red;
        box-shadow: 0 2px 2px 0 rgba(176, 39, 39, 0.14), 0 3px 1px -2px rgba(176, 39, 39, 0.2), 0 1px 5px 0 rgba(176, 39, 39,.12);
        &:hover {
            background-color: #b11b1b;
        }
    }
    &.done {
        background-color: #55b559;
        box-shadow: 0 2px 2px 0 rgba(39, 176, 46, 0.14), 0 3px 1px -2px rgba(39, 176, 46, 0.2), 0 1px 5px 0 rgba(39, 176, 46,.12);

        &:hover {
            background-color: #317934;
        }
    }
    &.disable {
        background-color: #999;
        box-shadow: 0 2px 2px 0 rgba(156, 156, 156, 0.14), 0 3px 1px -2px rgba(156, 156, 156, 0.2), 0 1px 5px 0 rgba(156, 156, 156,.12);
        cursor: no-drop;

        &:hover {
            background-color: #999;
        }
    }
    &.save {
        background-color: #ff9800;
        box-shadow: 0 2px 2px 0 rgba(194, 103, 17, 0.14), 0 3px 1px -2px rgba(194, 103, 17, 0.2), 0 1px 5px 0 rgba(194, 103, 17,.12);
    }
}

.buttons-row {
    align-items: center;
}